/* eslint-disable @typescript-eslint/no-non-null-assertion */
interface PublicEnv {
  env: 'local' | 'dev' | 'prod'
  host: string
  apiHost: string
  stripe: {
    apiKey: string
  }
  googleMap: {
    apiKey: string
  }
}

export const publicEnv: PublicEnv = {
  env: process.env.NEXT_PUBLIC_APP_ENV! as any,
  host: process.env.NEXT_PUBLIC_HOST!,
  apiHost: process.env.NEXT_PUBLIC_API_HOST!,
  stripe: {
    apiKey: process.env.NEXT_PUBLIC_STRIPE_KEY!,
  },
  googleMap: {
    apiKey: process.env.NEXT_PUBLIC_API_KEY!,
  },
}
