import { Styles } from '@chakra-ui/theme-tools'

export const styles: Styles = {
  global: {
    '@page': {
      size: 'A4 portrait',
      margin: '0mm',
    },
    html: {
      'scroll-behavior': 'smooth',
    },
    body: {
      '-webkit-print-color-adjust': 'exact',
    },
    li: {
      listStylePosition: 'inside',
    },
  },
}
