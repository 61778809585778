import { useDisclosure } from '@chakra-ui/hooks'
import {
  DrawerCloseButton,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  Text,
} from '@chakra-ui/react'
import { FC, ReactNode, createContext, useCallback, useContext, useState } from 'react'

export const ModalHeaderHeight = 64

const stub = (): never => {
  throw new Error()
}

const initialState: State & Dispatch = {
  isOpen: false,
  showModal: stub,
  hideModal: stub,
}

type State = {
  isOpen: boolean
  size?: ModalProps['size']
  hideCloseButton?: boolean
  closeOnOverlayClick?: boolean
  header?: string
  body?: ReactNode
}

export type ShowModalParams = {
  hideCloseButton?: boolean
  closeOnOverlayClick?: boolean
  size?: ModalProps['size']
  header?: State['header']
  body: State['body']
}

type Dispatch = {
  showModal: (params: ShowModalParams) => void
  hideModal: () => void
}

const StateContext = createContext<State>({ ...initialState })
const DispatchContext = createContext<Dispatch>({ ...initialState })

export const ModalProviderContainer: FC<{ children: ReactNode }> = ({ children }) => {
  const { state, dispatcher } = useCore()
  return (
    <StateContext.Provider value={state}>
      <DispatchContext.Provider value={{ ...dispatcher }}>
        {children}
        <Modal
          size={state.size}
          isOpen={state.isOpen}
          onClose={dispatcher.hideModal}
          scrollBehavior="inside"
          isCentered={true}
          autoFocus={false}
          closeOnOverlayClick={state.closeOnOverlayClick}
        >
          <ModalOverlay />
          <ModalContent maxH={'full'}>
            {state.header && (
              <ModalHeader p={0}>
                <Flex
                  align="center"
                  justify="center"
                  h={`${ModalHeaderHeight}px`}
                  backgroundColor="gray.50"
                  position="relative"
                  roundedTop="md"
                  overflow="hidden"
                >
                  <Text fontSize={20} fontWeight="medium" color="black" textAlign="center">
                    {state.header}
                  </Text>
                  <DrawerCloseButton color="blue.500" position="absolute" top={'50%'} right={4} transform="translateY(-50%)" />
                </Flex>
              </ModalHeader>
            )}
            {!state.hideCloseButton && <ModalCloseButton />}
            <ModalBody p={0}>{state.body}</ModalBody>
          </ModalContent>
        </Modal>
      </DispatchContext.Provider>
    </StateContext.Provider>
  )
}

const useCore = (): { state: State; dispatcher: Dispatch } => {
  const [state, setState] = useState<State>({ ...initialState })
  const { isOpen, onOpen, onClose } = useDisclosure()

  const showModal: Dispatch['showModal'] = useCallback(params => {
    setState({ isOpen: false, ...params })
    onOpen()
  }, [])

  const hideModal: Dispatch['hideModal'] = useCallback(() => {
    onClose()
  }, [])

  return {
    state: { ...state, isOpen },
    dispatcher: { showModal, hideModal },
  }
}

export const useModalState = () => useContext(StateContext)
export const useModalDispatcher = () => useContext(DispatchContext)
