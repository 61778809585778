import { ComponentStyleConfig } from '@chakra-ui/react'

export const button: ComponentStyleConfig = {
  baseStyle: {
    _disabled: {
      backgroundColor: 'gray.100',
      borderColor: 'gray.100',
      pointerEvents: 'none',
    },
  },
  sizes: {
    md: {
      height: 12,
      minHeight: 12,
      paddingX: 6,
    },
    lg: {
      height: 12,
      minHeight: 12,
      paddingX: 6,
    },
  },
  variants: {
    solid: props => ({
      rounded: '8px',
      fontSize: 16,
      fontWeight: 'medium',
      backgroundColor: `${props.colorScheme}.500`,
    }),
    outline: props => ({
      borderWidth: '2px',
      borderColor: `${props.colorScheme}.500`,
      color: `${props.colorScheme}.500`,
      backgroundColor: 'transparent',
      fontWeight: 'medium',
    }),
    link: {
      height: 'auto',
      minHeight: 'auto',
      paddingX: 0,
      fontWeight: 'normal',
      textDecoration: 'underline',
    },
  },
  defaultProps: {
    colorScheme: 'blue',
  },
}
