import { publicEnv } from 'env'
import { MyselfQuery, useMyselfLazyQuery } from 'generated/graphql'
import { FC, ReactNode, createContext, useCallback, useContext, useEffect, useState } from 'react'
import { useAuthState } from './auth'

const stub = (): never => {
  throw new Error()
}

const initialState: State = {
  initialized: false,
  loading: false,
}
const initialDispatch: Dispatch = {
  refresh: stub,
}

type State = {
  initialized: boolean
  loading: boolean
  myself?: MyselfQuery['myself']
  error?: unknown
}

type Dispatch = {
  refresh: () => void
}

const StateContext = createContext<State>({ ...initialState })
const DispatchContext = createContext<Dispatch>({ ...initialDispatch })

export const MyselfProviderContainer: FC<{ children: ReactNode }> = ({ children }) => {
  const { state, dispatcher } = useCore()

  return (
    <StateContext.Provider value={state}>
      <DispatchContext.Provider value={dispatcher}>{children}</DispatchContext.Provider>
    </StateContext.Provider>
  )
}

const useCore = (): { state: State; dispatcher: Dispatch } => {
  const { initialized, firebaseUser } = useAuthState()
  const [state, setState] = useState<State>({ ...initialState })
  const [fetchMyself, myselfState] = useMyselfLazyQuery({
    onCompleted: () => {
      setState(pre => ({ ...pre, loading: false }))
    },
  })

  const refresh = useCallback(() => {
    if (myselfState.loading) return
    // 型ではPromiseをを返してくれる風だがなぜかawaitしても一生終わらない
    fetchMyself()
  }, [myselfState.loading, fetchMyself])

  useEffect(() => {
    if (!initialized) return
    if (firebaseUser?.uid) {
      setState(pre => ({ ...pre, loading: true }))
      fetchMyself()
      return
    }

    setState(pre => ({ ...pre, initialized: true, myself: undefined }))
  }, [initialized, firebaseUser?.uid])

  useEffect(() => {
    if (!firebaseUser?.uid || !myselfState.called || myselfState.loading) return
    if (myselfState.error) {
      // eslint-disable-next-line no-console
      console.error(myselfState.error)
      setState(pre => ({ ...pre, initialized: true, user: undefined, error: myselfState.error }))
      return
    }

    if (publicEnv.env === 'local') {
      // eslint-disable-next-line no-console
      console.log({ myself: myselfState.data?.myself })
    }
    setState(pre => ({ ...pre, initialized: true, myself: myselfState.data?.myself, error: undefined }))
  }, [myselfState.data, myselfState.error, myselfState.loading, myselfState.called, firebaseUser?.uid, initialized])

  return {
    state: state,
    dispatcher: {
      refresh,
    },
  }
}

export const useMyselfState = () => useContext(StateContext)
export const useMyselfDispatcher = () => useContext(DispatchContext)
