import { ComponentStyleConfig } from '@chakra-ui/react'

export const radio: ComponentStyleConfig = {
  baseStyle: {
    label: {
      marginLeft: 2,
    },
    control: {
      backgroundColor: 'white',
      borderWidth: '2px',
      borderColor: 'blue.600',
      _hover: {
        borderColor: [null, 'blue.700'],
      },
      _checked: {
        backgroundColor: 'white',
        borderColor: 'blue.600',
        _before: {
          bg: 'blue.600',
        },
        _hover: {
          bg: [null, 'white'],
          borderColor: [null, 'blue.700'],
          _before: {
            bg: [null, 'blue.700'],
          },
        },
      },
    },
  },
  sizes: {
    md: {
      control: {
        width: '18px',
        height: '18px',
        _checked: {
          _before: {
            w: '10px',
            h: '10px',
          },
        },
      },
      label: {
        fontSize: 14,
      },
    },
  },
  variants: {},
  defaultProps: {},
}
