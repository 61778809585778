import { useDisclosure } from '@chakra-ui/hooks'
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertDialogProps,
  DrawerCloseButton,
  Flex,
  Text,
} from '@chakra-ui/react'
import { FocusableElement } from '@chakra-ui/utils'
import { FC, ReactNode, createContext, useCallback, useContext, useRef, useState } from 'react'

export const AlertDialogHeaderHeight = 64

const stub = (): never => {
  throw new Error()
}

const initialState: State & Dispatch = {
  isOpen: false,
  showAlertDialog: stub,
  hideAlertDialog: stub,
}

type State = {
  isOpen: boolean
  size?: AlertDialogProps['size']
  hideCloseButton?: boolean
  header?: string
  body?: ReactNode
}

export type ShowAlertDialogParams = {
  hideCloseButton?: boolean
  size?: AlertDialogProps['size']
  header?: State['header']
  body: State['body']
}

type Dispatch = {
  showAlertDialog: (params: ShowAlertDialogParams) => void
  hideAlertDialog: () => void
}

const StateContext = createContext<State>({ ...initialState })
const DispatchContext = createContext<Dispatch>({ ...initialState })

export const AlertDialogProviderContainer: FC<{ children: ReactNode }> = ({ children }) => {
  const { state, dispatcher } = useCore()
  const cancelRef = useRef<FocusableElement>(null)

  return (
    <StateContext.Provider value={state}>
      <DispatchContext.Provider value={{ ...dispatcher }}>
        {children}
        <AlertDialog
          size={state.size}
          isOpen={state.isOpen}
          leastDestructiveRef={cancelRef}
          onClose={dispatcher.hideAlertDialog}
          isCentered={true}
          autoFocus={false}
        >
          <AlertDialogOverlay />
          <AlertDialogContent maxH={'full'}>
            {state.header && (
              <AlertDialogHeader p={0}>
                <Flex
                  align="center"
                  justify="center"
                  h={`${AlertDialogHeaderHeight}px`}
                  backgroundColor="gray.50"
                  position="relative"
                  roundedTop="md"
                  overflow="hidden"
                >
                  <Text fontSize={20} fontWeight="medium" color="black" textAlign="center">
                    {state.header}
                  </Text>
                  <DrawerCloseButton color="blue.500" position="absolute" top={'50%'} right={4} transform="translateY(-50%)" />
                </Flex>
              </AlertDialogHeader>
            )}
            {!state.hideCloseButton && <AlertDialogCloseButton />}
            <AlertDialogBody p={0}>{state.body}</AlertDialogBody>
          </AlertDialogContent>
        </AlertDialog>
      </DispatchContext.Provider>
    </StateContext.Provider>
  )
}

const useCore = (): { state: State; dispatcher: Dispatch } => {
  const [state, setState] = useState<State>({ ...initialState })
  const { isOpen, onOpen, onClose } = useDisclosure()

  const showAlertDialog: Dispatch['showAlertDialog'] = useCallback(params => {
    setState({ isOpen: false, ...params })
    onOpen()
  }, [])

  const hideAlertDialog: Dispatch['hideAlertDialog'] = useCallback(() => {
    onClose()
  }, [])

  return {
    state: { ...state, isOpen },
    dispatcher: { showAlertDialog, hideAlertDialog },
  }
}

export const useAlertDialogState = () => useContext(StateContext)
export const useAlertDialogDispatcher = () => useContext(DispatchContext)
