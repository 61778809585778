import { getAnalytics } from 'firebase/analytics'
import { getApps, initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'

export const initFirebase = () => {
  const currentApps = getApps()
  if (currentApps.length !== 0) return currentApps[0]

  return initializeApp({
    apiKey: process.env.NEXT_PUBLIC_API_KEY ?? 'dummy',
    authDomain: process.env.NEXT_PUBLIC_AUTH_DOMAIN ?? 'dummy',
    databaseURL: process.env.NEXT_PUBLIC_DATABASE_URL ?? 'dummy',
    projectId: process.env.NEXT_PUBLIC_PROJECT_ID ?? 'dummy',
    storageBucket: process.env.NEXT_PUBLIC_STORAGE_BUCKET ?? 'dummy',
    messagingSenderId: process.env.NEXT_PUBLIC_MESSAGING_SENDER_ID ?? 'dummy',
    appId: process.env.NEXT_PUBLIC_APP_ID ?? 'dummy',
    measurementId: process.env.NEXT_PUBLIC_MEASUREMENT_ID ?? 'dummy',
  })
}

const app = initFirebase()

if (process.browser) {
  // Analyticsを一度呼んで初期化
  getAnalytics(app)
}

export const auth = getAuth(app)
export const analytics = () => {
  if (!process.browser) return
  return getAnalytics(app)
}
