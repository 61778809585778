import { ComponentStyleConfig } from '@chakra-ui/react'

export const table: ComponentStyleConfig = {
  baseStyle: {
    color: 'black',
    table: {
      borderColor: 'lightBlue.500',
      borderTopWidth: '1px',
      borderLeftWidth: '1px',

      caption: {
        color: 'black',
        fontSize: 20,
        lineHeight: '28px',
        fontWeight: 'medium',
        textAlign: 'left',
        marginTop: 0,
        paddingY: 4,
        backgroundColor: 'lightBlue.40',
        borderColor: 'lightBlue.500',
        borderTopWidth: '1px',
        borderRightWidth: '1px',
        borderLeftWidth: '1px',
      },
    },

    thead: {
      th: {
        color: 'black',
        fontSize: 14,
        lineHeight: '23px',
        textAlign: 'center',
        backgroundColor: 'lightBlue.40',
        borderColor: 'lightBlue.500',
        paddingX: 3,
        _last: {
          borderRightWidth: '1px',
        },
      },
    },

    tbody: {
      th: {
        color: 'black',
        fontSize: 16,
        fontWeight: 'normal',
        lineHeight: '24px',
        backgroundColor: 'blue.10',
        borderRightColor: 'lightBlue.500',
        borderRightWidth: '1px',
        borderBottomColor: 'lightBlue.40',
        paddingX: 4,
      },
      td: {
        fontSize: 16,
        textAlign: 'center',
        borderRightColor: 'lightBlue.40',
        borderRightWidth: '1px',
        borderBottomColor: 'lightBlue.40',
        paddingX: 4,
        _last: {
          borderRightColor: 'lightBlue.500',
        },
      },
      tr: {
        _last: {
          th: {
            borderBottomColor: 'lightBlue.500',
          },
          td: {
            borderBottomColor: 'lightBlue.500',
          },
        },
      },
    },

    tfoot: {
      tr: {
        borderBottomColor: 'lightBlue.500',
        borderBottomWidth: '1px',
        th: {
          color: 'black',
          fontSize: 16,
          fontWeight: 'normal',
          lineHeight: '24px',
          backgroundColor: 'blue.10',
          paddingY: 5,
          paddingX: 6,
          borderRightColor: 'lightBlue.500',
          borderRightWidth: '1px',
          borderBottomColor: 'lightBlue.500',
          borderBottomWidth: '1px',
        },
      },
      td: {
        textAlign: 'center',
        backgroundColor: 'blue.10',
        borderRightColor: 'lightBlue.40',
        borderRightWidth: '1px',
        borderBottomColor: 'lightBlue.500',
        _last: {
          borderRightColor: 'lightBlue.500',
        },
      },
    },
  },
  sizes: {
    sm: {
      tbody: {
        th: {
          paddingY: 2,
        },
      },
    },
    md: {
      tbody: {
        th: {
          paddingY: 5,
          paddingX: 6,
        },
      },
    },
  },
  variants: {},
  defaultProps: {},
}
