import { ApolloClient, createHttpLink, defaultDataIdFromObject, InMemoryCache } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { publicEnv } from 'env'
import { auth } from 'lib/firebase/client'
import generatedIntrospection from '../generated/fragmentTypes.json'
import { connectionStylePagination } from './pagination'

const isServer = typeof window === 'undefined'

const httpLink = createHttpLink({ uri: publicEnv.apiHost + '/graphql' })

const authLink = setContext(async (_, { headers }) => {
  let idToken: string | null | undefined
  try {
    idToken = await auth.currentUser?.getIdToken()
  } catch {
    idToken = null
  }

  // eslint-disable-next-line no-console
  if (publicEnv.env === 'local') console.log(`idToken: ${idToken}`)

  return {
    headers: {
      ...headers,
      authorization: idToken ? `Bearer ${idToken}` : '',
    },
  }
})

const _client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache({
    possibleTypes: generatedIntrospection.possibleTypes,
    dataIdFromObject: responseObject => {
      switch (responseObject.__typename) {
        case 'PropertyContentInDetail':
          return `PropertyContentInDetail:${responseObject.id}:${responseObject.language ?? 'ja-JP'}`
        default:
          return defaultDataIdFromObject(responseObject)
      }
    },
    typePolicies: {
      Query: {
        fields: {
          travels: connectionStylePagination(['input']),
          employees: connectionStylePagination(false),
          invoices: connectionStylePagination(false),
          newsList: connectionStylePagination(false),
        },
      },
    },
  }),
  defaultOptions: {
    watchQuery: { fetchPolicy: 'cache-and-network', nextFetchPolicy: 'cache-and-network' },
    query: { fetchPolicy: isServer ? 'no-cache' : 'network-only' },
  },
})

export const apolloClient = _client
