import { ComponentStyleConfig } from '@chakra-ui/react'

export const tabs: ComponentStyleConfig = {
  baseStyle: {
    tab: {
      transitionDuration: '0ms',
    },
  },
  sizes: {
    md: {
      tab: {
        fontSize: 14,
        py: 3,
        px: 4,
      },
    },
  },
  variants: {
    line: props => ({
      tablist: {
        borderBottomWidth: '1px',
        borderColor: 'gray.100',
      },
      tab: {
        fontWeight: 'normal',
        color: 'black',
        _selected: {
          fontWeight: 'bold',
          color: `${props.colorScheme}.400`,
          borderColor: `${props.colorScheme}.400`,
        },
      },
    }),
  },
  defaultProps: {},
}
