import {
  EmailAuthProvider,
  updateEmail as _updateEmail,
  updatePassword as _updatePassword,
  applyActionCode,
  checkActionCode,
  confirmPasswordReset,
  createUserWithEmailAndPassword,
  reauthenticateWithCredential,
  signInWithEmailAndPassword,
  updateProfile,
  verifyPasswordResetCode,
} from '@firebase/auth'
import { publicEnv } from 'env'
import type { User as FirebaseUser } from 'firebase/auth'
import { auth } from 'lib/firebase/client'
import { FC, ReactNode, createContext, useCallback, useContext, useEffect, useState } from 'react'

const stub = (): never => {
  throw new Error()
}

const initialAuthState: AuthState = {
  initialized: false,
}
const initialAuthDispatch: AuthDispatch = {
  signUp: stub,
  signIn: stub,
  signOut: stub,
  updateEmail: stub,
  updatePassword: stub,
  confirmResetPassword: stub,
  resetEmailChange: stub,
}

export type SignUpParams = {
  name: string
  email: string
  password: string
}

export type SignInParams = {
  email: string
  password: string
}

export type UpdateEmailParams = {
  newEmail: string
  currentPassword: string
}

export type UpdatePasswordParams = {
  current: string
  newPassword: string
}

export type ConfirmResetPasswordParams = {
  code: string
  newPassword: string
}

export type ResetEmailChangeParams = {
  code: string
}

type AuthErrorCode = 'unexpected'

type AuthState = {
  initialized: boolean
  firebaseUser?: FirebaseUser
  errorCode?: AuthErrorCode
}

type AuthDispatch = {
  signUp: (params: SignUpParams) => Promise<void>
  signIn: (params: SignInParams) => Promise<any>
  signOut: () => Promise<void>
  updateEmail: (params: UpdateEmailParams) => Promise<void>
  updatePassword: (params: UpdatePasswordParams) => Promise<void>
  confirmResetPassword: (params: ConfirmResetPasswordParams) => Promise<void>
  resetEmailChange: (params: ResetEmailChangeParams) => Promise<{ restoredEmail: string | null | undefined }>
}

const AuthStateContext = createContext<AuthState>({ ...initialAuthState })
const AuthDispatchContext = createContext<AuthDispatch>({ ...initialAuthDispatch })

export const AuthProviderContainer: FC<{ children: ReactNode }> = ({ children }) => {
  const { state, dispatcher } = useAuthCore()

  return (
    <AuthStateContext.Provider value={state}>
      <AuthDispatchContext.Provider value={dispatcher}>{children}</AuthDispatchContext.Provider>
    </AuthStateContext.Provider>
  )
}

const useAuthCore = (): { state: AuthState; dispatcher: AuthDispatch } => {
  const [authState, setAuthState] = useState<AuthState>({ ...initialAuthState })

  const signUp = useCallback(async (params: SignUpParams) => {
    const result = await createUserWithEmailAndPassword(auth, params.email, params.password)
    await updateProfile(result.user, { displayName: params.name })
  }, [])

  const signIn = useCallback(async (params: SignInParams) => {
    return signInWithEmailAndPassword(auth, params.email, params.password)
  }, [])

  const signOut = useCallback(async () => {
    await auth.signOut()
  }, [])

  const reauthenticate = useCallback(async (firebaseUser: FirebaseUser, params: { email: string; password: string }) => {
    const credential = EmailAuthProvider.credential(params.email, params.password)
    return await reauthenticateWithCredential(firebaseUser, credential)
  }, [])

  const updateEmail = useCallback(
    async (params: UpdateEmailParams) => {
      if (!authState.firebaseUser?.email) throw new Error('no user found')
      await reauthenticate(authState.firebaseUser, { email: authState.firebaseUser.email, password: params.currentPassword })
      await _updateEmail(authState.firebaseUser, params.newEmail)
      await signIn({ email: params.newEmail, password: params.currentPassword })
      // // force refreshしないとid tokenのメアド変わらないので更新
      // await authState.firebaseUser.getIdTokenResult(true)
    },
    [authState.firebaseUser, signIn]
  )

  const updatePassword = useCallback(
    async (params: UpdatePasswordParams) => {
      const email = authState.firebaseUser?.email
      if (!authState.firebaseUser || !email) return

      const result = await reauthenticate(authState.firebaseUser, { email, password: params.current })
      await _updatePassword(result.user, params.newPassword)
    },
    [authState.firebaseUser?.email]
  )

  const confirmResetPassword = useCallback(async (params: ConfirmResetPasswordParams) => {
    const email = await verifyPasswordResetCode(auth, params.code)
    await confirmPasswordReset(auth, params.code, params.newPassword)
    await signIn({ email, password: params.newPassword })
  }, [])

  const resetEmailChange = useCallback(async ({ code }: ResetEmailChangeParams) => {
    const result = await checkActionCode(auth, code)
    await applyActionCode(auth, code)
    return { restoredEmail: result.data.email }
  }, [])

  useEffect(() => {
    if (!process.browser) return
    let cancel = false

    const unsubscribe = auth.onAuthStateChanged(
      async firebaseUser => {
        if (cancel) return
        if (firebaseUser) {
          setAuthState(pre => ({ ...pre, initialized: true, firebaseUser }))
          return
        }

        setAuthState(pre => ({ ...pre, initialized: true, firebaseUser: undefined }))
      },
      error => {
        if (cancel) return
        // eslint-disable-next-line no-console
        console.error(error)
        setAuthState(pre => ({ ...pre, initialized: true, errorCode: 'unexpected' }))
      }
    )

    return () => {
      cancel = true
      unsubscribe()
    }
  }, [process.browser])

  useEffect(() => {
    if (publicEnv.env !== 'prod') {
      // eslint-disable-next-line no-console
      console.log(authState)
    }
  }, [authState])

  return {
    state: authState,
    dispatcher: {
      signUp,
      signIn,
      signOut,
      updatePassword,
      updateEmail,
      confirmResetPassword,
      resetEmailChange,
    },
  }
}

export const useAuthState = () => useContext(AuthStateContext)
export const useAuthDispatcher = () => useContext(AuthDispatchContext)
