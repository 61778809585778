import { extendTheme } from '@chakra-ui/react'
import { components } from './components'
import { colors } from './foundations/colors'
import { shadows } from './foundations/shadows'
import { styles } from './styles'

export const customTheme = extendTheme({
  components,
  colors,
  shadows,
  fonts: {
    heading: 'Noto Sans JP',
    body: 'Noto Sans JP',
  },
  styles,
})
