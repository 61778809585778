import { ComponentStyleConfig } from '@chakra-ui/react'

export const select: ComponentStyleConfig = {
  baseStyle: {
    field: {
      backgroundColor: 'white',
      border: '1px solid',
      borderColor: 'blue.600',
    },
  },
  sizes: {
    md: {
      field: {
        fontSize: 14,
        height: 12,
      },
      icon: {
        color: 'blue.600',
        fontSize: '1.6rem',
      },
    },
  },
  variants: {
    outline: {
      field: {
        backgroundColor: 'white',
        border: '1px solid',
        borderColor: 'blue.600',
      },
    },
    withIcon: {
      field: {
        paddingLeft: '44px',
      },
    },
  },
  defaultProps: {},
}
