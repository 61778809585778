import { ComponentStyleConfig } from '@chakra-ui/react'

export const input: ComponentStyleConfig = {
  baseStyle: {
    _placeholder: {
      fontSize: 15,
      color: 'gray.300',
    },
  },
  sizes: {
    md: {
      field: {
        fontSize: 15,
      },
    },
  },
  variants: {
    outline: {
      field: {
        backgroundColor: 'white',
        border: '1px solid',
        borderColor: 'blue.600',
        height: 12,
        rounded: 'lg',
      },
    },
  },
  defaultProps: {},
}
