import { ApolloProvider } from '@apollo/client'
import { ChakraProvider } from '@chakra-ui/react'
import { css, Global } from '@emotion/react'
import '@fontsource/noto-sans-jp/400.css'
import '@fontsource/noto-sans-jp/500.css'
import '@fontsource/noto-sans-jp/700.css'
import { Elements } from '@stripe/react-stripe-js'
import { DefaultSeo } from 'components/functional/seo/DefaultSeo'
import { AuthProviderContainer } from 'context/auth'
import { MyselfProviderContainer } from 'context/myself'
import { AlertDialogProviderContainer } from 'context/ui/alertDialog'
import { ModalProviderContainer } from 'context/ui/modal'
import 'focus-visible/dist/focus-visible'
import { apolloClient } from 'graphql/apollo'
import { stripePromise } from 'lib/stripe'
import { AppProps } from 'next/app'
import Head from 'next/head'
import { FC, ReactNode } from 'react'
import { customTheme } from 'theme'

const GlobalStyles = css`
  /*
    This will hide the focus indicator if the element receives focus    via the mouse,
    but it will still show up on keyboard focus.
  */
  .js-focus-visible :focus:not([data-focus-visible-added]) {
    outline: none;
    box-shadow: none;
  }
`

const Providers: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <ChakraProvider theme={customTheme}>
      <ApolloProvider client={apolloClient}>
        <Global styles={GlobalStyles} />
        <AuthProviderContainer>
          <MyselfProviderContainer>
            <Elements stripe={stripePromise}>
              <AlertDialogProviderContainer>
                <ModalProviderContainer>{children}</ModalProviderContainer>
              </AlertDialogProviderContainer>
            </Elements>
          </MyselfProviderContainer>
        </AuthProviderContainer>
      </ApolloProvider>
    </ChakraProvider>
  )
}

const App = ({ Component, pageProps }: AppProps) => {
  return (
    <>
      <Head>
        <title>{`wcc-bota`}</title>
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <link rel="shortcut icon" href="/favicon.ico" key="shortcutIcon" />
      </Head>
      <DefaultSeo />

      <Providers>
        <Component {...pageProps} />
      </Providers>
    </>
  )
}

export default App
