import { ComponentStyleConfig } from '@chakra-ui/react'

export const textarea: ComponentStyleConfig = {
  baseStyle: {
    _placeholder: {
      fontSize: 15,
      color: 'gray.300',
    },
  },
  sizes: {
    md: {
      fontSize: 15,
      paddingY: 3,
    },
  },
  variants: {
    outline: {
      backgroundColor: 'white',
      border: '1px solid',
      borderColor: 'blue.600',
    },
  },
  defaultProps: {},
}
