import { ComponentStyleConfig } from '@chakra-ui/react'

export const tooltip: ComponentStyleConfig = {
  baseStyle: {
    background: 'white',
    color: 'black',
    fontSize: 12,
    lineHeight: '16px',
    paddingY: 4,
    paddingX: 5,
    rounded: 'lg',
    boxShadow: '0px 2px 4px 0px #00000040',
    minW: '480px',
  },
  sizes: {},
  variants: {
    gray: {
      background: 'gray.700',
      color: 'white',
      fontSize: 14,
      lineHeight: '20px',
      paddingY: 2,
      paddingX: 2,
      rounded: 'md',
      boxShadow: 'none',
      minW: 'auto',
    },
  },
  defaultProps: {},
}
