import { ThemeComponents } from '@chakra-ui/react'
import { button } from 'theme/components/button'
import { checkbox } from './checkbox'
import { container } from './container'
import { formLabel } from './form-label'
import { input } from './input'
import { link } from './link'
import { popover } from './popover'
import { radio } from './radio'
import { select } from './select'
import { table } from './table'
import { tabs } from './tabs'
import { textarea } from './textarea'
import { tooltip } from './tooltip'

export const components: ThemeComponents = {
  Button: button,
  Checkbox: checkbox,
  Container: container,
  FormLabel: formLabel,
  Input: input,
  Link: link,
  Popover: popover,
  Radio: radio,
  Select: select,
  Table: table,
  Tabs: tabs,
  Textarea: textarea,
  Tooltip: tooltip,
}
