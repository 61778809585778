import { v4 } from 'uuid'

export function compactMap<T, R>(array: T[], handle: (data: T, index: number) => R | null | undefined) {
  const list: R[] = []
  array.forEach((data, i) => {
    const result = handle(data, i)
    if (result !== null && result !== undefined) {
      list.push(result)
    }
  })
  return list
}

export function removeDuplicatesByKey<E extends { [key: string]: any }>(array: E[], key: string): E[] {
  const set = new Set<string>()
  const mapping: { [key: string]: E } = {}
  for (const element of array) {
    const id = element ? `${element[key]}` : v4()
    set.add(id)
    mapping[id] = element
  }
  return Array.from(set).map(key => mapping[key])
}
