import { ComponentStyleConfig } from '@chakra-ui/react'

export const container: ComponentStyleConfig = {
  sizes: {
    content: {
      minWidth: '1240px',
      width: '1240px',
      paddingX: 5,
    },
  },
  variants: {},
  defaultProps: {},
}
