import { publicEnv } from 'env'
import { DefaultSeo as DS } from 'next-seo'
import { FC } from 'react'

export const defaultOgImageURL = `${publicEnv.host}/assets/ogp/default.png`
export const defaultOgTitle = 'HoteCo｜国内・海外ホテル予約サービス'
export const defaultOgDescription =
  '株式会社ワールドコンパスが運営するホテル予約サービス。豊富なホテル在庫 / 安心のサポート / 請求書精算に対応。ホテル手配する方をサポート。'

export const DefaultSeo: FC = () => {
  const serviceName = 'HoteCo'
  const defaultTitle = `${serviceName} | 国内・海外ホテル予約サービス`
  return (
    <DS
      defaultTitle={defaultTitle}
      description={defaultOgDescription}
      dangerouslySetAllPagesToNoIndex={publicEnv.env !== 'prod'}
      dangerouslySetAllPagesToNoFollow={publicEnv.env !== 'prod'}
      openGraph={{
        site_name: serviceName,
        title: defaultTitle,
        description: defaultOgDescription,
        url: publicEnv.host,
        images: [{ url: `${publicEnv.host}/assets/ogp/default.png` }],
        locale: 'ja_JP',
        type: 'website',
      }}
      twitter={{ cardType: 'summary_large_image' }}
    />
  )
}
