import { ComponentStyleConfig } from '@chakra-ui/react'

export const checkbox: ComponentStyleConfig = {
  baseStyle: {
    label: {
      marginLeft: 2,
    },
    control: {
      bg: 'white',
      borderWidth: '1px',
      borderColor: 'blue.600',
      rounded: '2px',
      _disabled: {
        bg: 'white',
        borderColor: 'gray.200',
      },
      _checked: {
        bg: 'blue.600',
        borderColor: 'blue.600',
        _hover: {
          bg: [null, 'blue.700'],
          borderColor: [null, 'blue.700'],
        },
      },
    },
  },
  sizes: {
    md: {
      control: {
        width: '18px',
        height: '18px',
      },
      label: {
        fontSize: 14,
      },
    },
  },
  variants: {},
  defaultProps: {},
}
