import { ComponentStyleConfig } from '@chakra-ui/react'

export const formLabel: ComponentStyleConfig = {
  baseStyle: {
    color: 'black',
    fontSize: 13,
    lineHeight: 4,
    marginBottom: '5px',
    marginRight: 0,
  },
  sizes: {},
  variants: {},
  defaultProps: {},
}
