import { ComponentStyleConfig } from '@chakra-ui/react'

export const popover: ComponentStyleConfig = {
  baseStyle: {
    content: {
      border: 'none',
      filter: 'drop-shadow(0px 2px 4px #00000040)',
      width: 'auto',
      maxWidth: '320px',
    },
  },
  sizes: {},
  variants: {},
  defaultProps: {},
}
