// 参考
// https://github.com/apollographql/apollo-client/blob/master/src/utilities/policies/pagination.ts

import { FieldPolicy, Reference } from '@apollo/client'
import { removeDuplicatesByKey } from 'helper/array'

type KeyArgs = FieldPolicy<any>['keyArgs']

type TInternalConnection<TNode> = Readonly<{
  nodes: Array<TNode>
  pageInfo: Readonly<{
    hasNextPage: boolean
    endCursor: string | undefined
  }>
}>

// nodesとpageInfoがあるやつで使える
// 引数のkeyArgsはrefetchの際に
// - キャッシュをパージする必要がない場合にはfalse
// - パージしてほしい場合は、そのクエリの引数のどれが変わったらパージしてほしいのかを書く
export const connectionStylePagination = <TNode extends Reference = Reference>(
  keyArgs: KeyArgs = false
): FieldPolicy<TInternalConnection<TNode>> => {
  return {
    keyArgs,

    read(existing, { canRead }) {
      if (!existing) return
      const nodes = existing.nodes.filter(node => canRead(node))
      return { ...existing, nodes, pageInfo: existing.pageInfo }
    },

    merge(existing = makeEmptyData(), incoming, { args }) {
      if (!args) return existing
      if (!args?.pagination?.cursor && incoming) return incoming

      // 重複排除
      const nodes: TNode[] = removeDuplicatesByKey([...existing.nodes, ...incoming.nodes], '__ref')
      const pageInfo = {
        ...existing.pageInfo,
        ...incoming.pageInfo,
      }

      return {
        ...existing,
        ...incoming,
        nodes,
        pageInfo,
      }
    },
  }
}

function makeEmptyData() {
  return {
    nodes: [],
    pageInfo: {
      hasNextPage: false,
      endCursor: undefined,
    },
  }
}
