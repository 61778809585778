import { Colors } from '@chakra-ui/react'

export const colors: Colors = {
  black: '#030F1C',
  white: '#ffffff',
  primary: '#2C67AC',
  secondary: '#75BBE1',
  link: '#1859FF',
  reviewText: '#FF482F',
  borderBase: '#D5DAD4',
  headerLink: '#0967A8',
  gray: {
    '10': '#F8F8F8',
    '20': '#F5F5F5',
    '30': '#E9E9E9',
    '40': '#DEDEDE',
    '50': '#F2F2F2',
    '100': '#DBDBDB',
    '200': '#C4C4C4',
    '300': '#ADADAD',
    '400': '#969696',
    '500': '#808080',
    '600': '#666666',
    '700': '#4D4D4D',
    '800': '#333333',
    '900': '#1A1A1A',
  },
  blue: {
    '10': '#FAFBFF',
    '50': '#EBF2FA',
    '60': '#7CABE2',
    '100': '#C6DAF0',
    '200': '#A2C2E7',
    '300': '#7DAADE',
    '400': '#5892D4',
    '500': '#347ACB',
    '600': '#2C67AC',
    '700': '#1F497A',
    '800': '#153151',
    '900': '#0A1829',
  },
  red: {
    '10': '#FFEDED',
    '50': '#FEE7E7',
    '100': '#FCBBBB',
    '200': '#FA8F8F',
    '300': '#F86363',
    '400': '#F63737',
    '500': '#E30A0A',
    '600': '#C30909',
    '700': '#930606',
    '800': '#620404',
    '900': '#310202',
  },
  orange: {
    '50': '#FEF2E7',
    '100': '#FBD9BC',
    '200': '#F8C190',
    '300': '#F6A965',
    '400': '#F28729',
    '500': '#F0780F',
    '600': '#C0600C',
    '700': '#904809',
    '800': '#603006',
    '900': '#301803',
  },
  yellow: {
    '50': '#FDF8E7',
    '100': '#FBEBBC',
    '200': '#F8DE91',
    '300': '#F5D265',
    '400': '#F2C230',
    '500': '#F0B80F',
    '600': '#C0930C',
    '700': '#906F09',
    '800': '#604A06',
    '900': '#302503',
  },
  pink: {
    '50': '#FDE7F3',
    '100': '#FABDDE',
    '200': '#F692C8',
    '300': '#F25CAD',
    '400': '#F03D9E',
    '500': '#EC1388',
    '600': '#BD0F6D',
    '700': '#8E0B52',
    '800': '#5E0836',
    '900': '#2F041B',
  },
  green: {
    '50': '#EDFBEA',
    '100': '#CBF3C4',
    '200': '#AAEB9E',
    '300': '#89E378',
    '400': '#67DB52',
    '500': '#39AB24',
    '600': '#38A923',
    '700': '#2A7E1B',
    '800': '#1C5412',
    '900': '#0E2A09',
  },
  lightBlue: {
    '40': '#E0F3FA',
    '100': '#75BBE1',
    '500': '#75BBE1',
  },
}
